import { useContext, useEffect, useState } from 'react';
import { FieldsteadAPIConfigContext } from '../contexts/fieldsteadapicontext';

import { IContactSummary, IContactDetails, IMailLog, IServiceUser, IDocumentRequest, ISubscription, IEntityTag, ITag } from '../application/models';

import flagdata from '../application/flags.json';
import { AuthenticationInfo } from '../contexts/authcontext';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { CognitoUserSession, GetSessionOptions } from 'amazon-cognito-identity-js';


interface ReqData {
    url: string;
    method: "GET" | "POST" | "PUT" | "DELETE";
    body?: object;
    contenttype?: string;
}

export interface ISearchCriteria {
    fullname: boolean;
    children: boolean;
    company: boolean;
    relationships: boolean;
    email: boolean;
    location: boolean;
    notes: boolean;
}

export interface FlagData {
    name: string;
    flag: string;
}

export interface IFormData {
    contacts: Array<string>;
    categories: Array<string>;
}

export const useFieldsteadApi = () => {
    const [config, setConfig] = useContext(FieldsteadAPIConfigContext);
    const { user } = useAuthenticator((context) => [context.user]);

    //const [authinfo, setAuthInfo] = useState<AuthenticationInfo | null>(null);

    //useEffect(() => {
    //    if (user != null) {
    //        user.getSession((err: Error | null, session: CognitoUserSession) => {
    //            let token: string = session.getIdToken().getJwtToken();
    //            let a: AuthenticationInfo = new AuthenticationInfo(token);
    //            setAuthInfo(a);
    //        });
    //    }
    //    else {
    //        setAuthInfo(null);
    //    }
    //}, [user]);

    function GenerateReqInit(req: ReqData, token: string | null): RequestInit {
        let reqinit: RequestInit = {
            method: req.method,
            mode: 'cors',
            redirect: 'follow',
            headers: token != null ? {
                "Authorization": "Bearer " + token,
                "Access-Control-Allow-Origin": "*"
            } : {}
        };
        if (req.body != null) {
            reqinit = { ...reqinit, body: JSON.stringify(req.body) }
        }
        if (req.contenttype != null) {
            reqinit.headers = { ...reqinit.headers, "Content-Type": req.contenttype };
        }

        return reqinit;
    }

    function AuthorizeRequest(request: ReqData): Promise<RequestInit> {
        return new Promise<RequestInit>((resolve, reject) => {
            user.getSession((error: Error | null, session: CognitoUserSession | null) => {
                if (session != null) {
                    user.refreshSession(session.getRefreshToken(), (err, ref) => {
                        let auth: AuthenticationInfo = new AuthenticationInfo(ref.getIdToken().getJwtToken());
                        resolve(GenerateReqInit(request, auth.token));
                    });
                }
                else {
                    throw new Error("Unauthenticated AuthRequest");
                }
            });
        });
    }

    function ReqListAuthenticated<T>(request: ReqData, hydrater?: ((d: any) => T) | undefined): Promise<Array<T>> {
        return new Promise<Array<T>>((resolve, reject) => {
            AuthorizeRequest(request).then((init) => {
                let url = request.url;
                return fetch(url, init)
                    .then(response => {
                        if (response.ok) { return response.json(); }
                        else throw new Error("Authenticated fetch failed: " + url);
                    }).then(d => {
                        if (hydrater != null) {
                            let vals: Array<T> = d.map((v: any, i: number) => {
                                return hydrater(v);
                            });
                            resolve(vals);
                        }
                        else {
                            resolve(d);
                        }
                    });
            });
            //user.getSession((error: Error | null, session: CognitoUserSession | null) => {
            //    if (session != null) {
            //        let auth: AuthenticationInfo = new AuthenticationInfo(session.getIdToken().getJwtToken());
            //        let init: RequestInit = GenerateReqInit(request, auth.token);
            //        let url = request.url;
            //        return fetch(url, init)
            //            .then(response => {
            //                if (response.ok) { return response.json(); }
            //                else throw new Error("Authenticated fetch failed: " + url);
            //            }).then(d => {
            //                if (hydrater != null) {
            //                    let vals: Array<T> = d.map((v: any, i: number) => {
            //                        return hydrater(v);
            //                    });
            //                }
            //            });
            //    }
            //    else {
            //        throw new Error("Unauthenticated Request");
            //    }
            //});
        });
    }

    function ReqAuthenticated<T>(request: ReqData, hydrater?: ((d: any) => T) | undefined): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            AuthorizeRequest(request).then((init) => {
                let url = request.url;
                return fetch(url, init)
                    .then(response => {
                        if (response.ok) return response.json();
                        else throw new Error("Authenticated fetch failed: " + url);
                    })
                    .then(d => {
                        if (hydrater == null) {
                            resolve(d);
                        }
                        else {
                            let outitem = hydrater(d);
                            resolve(outitem);
                        }
                    })
                    .catch(error => {
                        reject(error.message);
                    });
            });
            //user.getSession((error: Error | null, session: CognitoUserSession | null) => {
            //    if (session != null) {
            //        let auth: AuthenticationInfo = new AuthenticationInfo(session.getIdToken().getJwtToken());
            //        let init: RequestInit = GenerateReqInit(request, auth.token);
            //        let url = request.url;
            //        return fetch(url, init)
            //            .then(response => {
            //                if (response.ok) return response.json();
            //                else throw new Error("Authenticated fetch failed: " + url);
            //            })
            //            .then(d => {
            //                if (hydrater == null) {
            //                    resolve(d);
            //                }
            //                else {
            //                    let outitem = hydrater(d);
            //                    resolve(outitem);
            //                }
            //            })
            //            .catch(error => {
            //                reject(error.message);
            //            });
            //    }
            //    else {
            //        throw new Error("Unauthenticated Request");
            //    }
            //});
        });
    }

    function SendAuthenticated(request: ReqData): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            AuthorizeRequest(request).then((init) => {
                let url = request.url;
                return fetch(url, init)
                    .then(response => {
                        if (response.ok) { resolve({ success: true, message: "Request Successful" }); }
                        else throw new Error("Authenticated send failed: " + url);
                    })
                    .catch(error => {
                        reject(error.message);
                    });
            });
            //user.getSession((error: Error | null, session: CognitoUserSession | null) => {
            //    if (session != null) {
            //        let auth: AuthenticationInfo = new AuthenticationInfo(session.getIdToken().getJwtToken());
            //        let init: RequestInit = GenerateReqInit(request, auth.token);
            //        let url = request.url;
            //        return fetch(url, init)
            //            .then(response => {
            //                if (response.ok) { resolve({ success: true, message: "Request Successful" }); }
            //                else throw new Error("Authenticated send failed: " + url);
            //            })
            //            .catch(error => {
            //                reject(error.message);
            //            });
            //    }
            //    else {
            //        throw new Error("Unauthenticated Request");
            //    }
            //});
        });
    }

    function GetUserInfo(): Promise<AuthenticationInfo | null> {
        return new Promise<AuthenticationInfo | null>((resolve, reject) => {
            if (user == null) {
                reject("User not found");
            }
            else {
                user.getSession((error: Error | null, session: CognitoUserSession | null) => {
                    if (session != null) {
                        let auth: AuthenticationInfo = new AuthenticationInfo(session.getIdToken().getJwtToken());
                        resolve(auth);
                    }
                    else {
                        reject("Session not found");
                    }
                });
            }
        });
    }

    function FetchContact(contactid: string): Promise<IContactDetails> {
        let url: string = config.endpointurl + '/Contact/' + contactid;
        let req: ReqData = {
            url: url,
            method: "GET",
            contenttype: "application/json"
        };

        return new Promise<IContactDetails>((resolve, reject) => {
            ReqAuthenticated<IContactDetails>(req)
                .then((data) => {
                    resolve(data);
                }).catch((r) => { reject("Error fetching contact details: " + r.message) });
        });
    }

    function HydrateMailLog(data: any): IMailLog {
        return {
            id: data.id,
            type: data.type,
            category: data.category,
            receiveddate: data.receiveddate != null ? new Date(data.receiveddate) : null,
            routed: data.routed,
            sent: data.sent,
            comment: data.comment,
            invdate: data.invdate != null ? new Date(data.invdate) : null,
            invloc: data.invloc,
            invrsvp: data.invrsvp,
            invaddressstreet: data.invaddressstreet,
            invaddresscity: data.invaddresscity,
            invaddressstate: data.invaddressstate,
            invaddresszip: data.invaddresszip,
            invstarttime: data.invstarttime != null ? new Date(data.invstarttime) : null,
            invendtime: data.invendtime != null ? new Date(data.invendtime) : null,
            invresponsedate: data.invresponsedate != null ? new Date(data.invresponsedate) : null,
            user: data.user,
            folder: data.folder,
            from: data.from,
            timesensitive: data.timesensitive,
            to215: data.to215
        }
    }

    function FetchMailLog(startdate: Date, enddate: Date): Promise<Array<IMailLog>> { //, callback: (data: Array<IMailLog>) => void): void {
        return new Promise<Array<IMailLog>>((resolve, reject) => {
            let url: string = config.endpointurl + "/MailLog/";
            let startstring: string = startdate.getFullYear().toString() + "-" + (startdate.getMonth() + 1).toString() + "-" + startdate.getDate().toString();
            let endstring: string = enddate.getFullYear().toString() + "-" + (enddate.getMonth() + 1).toString() + "-" + enddate.getDate().toString();
            let fullurl: string = url + "?startdate=" + encodeURIComponent(startstring) + "&enddate=" + encodeURIComponent(endstring);
            let req: ReqData = {
                method: "GET",
                contenttype: "application/json",
                url: fullurl
            }
            /*
            let req: RequestInit = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.authtoken
                },
                method: "GET"
            };
            */

            ReqListAuthenticated<IMailLog>(req, HydrateMailLog)
                .then((data) => { resolve(data); })
                .catch((err) => { reject(err.message); });
        });
    }

    function FetchMailLogEntry(id: number): Promise<IMailLog> { //, callback: (data: IMailLog) => void): void {
        return new Promise<IMailLog>((resolve, reject) => {
            let url: string = config.endpointurl + "/MailLog/" + id.toString();
            let req: ReqData = {
                method: "GET",
                contenttype: "application/json",
                url: url
            }

            ReqAuthenticated<IMailLog>(req, HydrateMailLog)
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => { reject(err.message) });
        });
    }

    function SaveMailLogEntry(maillog: IMailLog): Promise<string> { //, callback: (responsestring: string) => void): void {
        return new Promise<string>((resolve, reject) => {
            let url: string = config.endpointurl + "/MailLog/";
            let req: ReqData = {
                method: "POST",
                contenttype: "application/json",
                url: url,
                body: maillog
            };

            ReqAuthenticated<string>(req)
                .then((response) => {
                    if (response != null) {
                        resolve(response);
                    }
                    else {
                        resolve("Failure");
                    }
                })
                .catch((error) => {
                    resolve("Error");
                });
        });
    }

    function FetchDirectoryEntries(letter: string, flag: string, text: string, searchcriteria: ISearchCriteria): Promise<Array<IContactSummary>> { //, callback: (data: Array<IContactSummary>) => void): void {
        let url: string = config.endpointurl + "/Contact";
        let searchflags: number = 0;
        let modifier: string = "";

        searchflags = (searchcriteria.fullname ? 1 : 0)
            + (searchcriteria.children ? 2 : 0)
            + (searchcriteria.company ? 4 : 0)
            + (searchcriteria.relationships ? 8 : 0)
            + (searchcriteria.email ? 16 : 0)
            + (searchcriteria.location ? 32 : 0)
            + (searchcriteria.notes ? 64 : 0);

        if (letter != "") {
            modifier = "letter=" + letter;
        }
        if (flag != "") {
            if (modifier.length > 0) modifier = modifier + "&";
            modifier = modifier + "tag=" + flag;
        }
        if (text != "") {
            if (modifier.length > 0) modifier = modifier + "&";
            modifier = modifier + "search=" + text.replace("<", "");
        }
        if (searchflags > 0) {
            if (modifier.length > 0) modifier = modifier + "&";
            modifier = modifier + "flds=" + searchflags.toString();
        }

        let req: ReqData = {
            url: url + (modifier.length > 0 ? ("?" + modifier) : ""),
            method: "GET",
            contenttype: "application/json"
        };

        return new Promise<Array<IContactSummary>>((resolve, reject) => {
            ReqAuthenticated<Array<IContactSummary>>(req)
                .then((data) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject("Error fetching contact summaries: " + err.message);
                });
        });
    }

    function GetStates(): Array<FlagData> {
        return flagdata.locations.states;
    }

    function GetInternational(): Array<FlagData> {
        return flagdata.locations.abroad;
    }

    function GetPersonal(): Array<FlagData> {
        return flagdata.personal;
    }

    function GetOtherOptions(): Array<FlagData> {
        return flagdata.other;
    }

    function FetchEntityTags(entitytype: string, entityid: string | number): Promise<Array<IEntityTag>> {
        return new Promise<Array<IEntityTag>>((resolve, reject) => {
            let url: string = config.endpointurl + "/EntityTags/" + entitytype + "/" + entityid.toString();

            let req: ReqData = {
                url: url,
                contenttype: "application/json",
                method: "GET"
            };

            ReqAuthenticated<Array<IEntityTag>>(req).then(results => {
                resolve(results); //.map((v: any, i: number) => { return HydrateEntityTag(v); }));
            }).catch(error => {
                reject("API Error");
            });
        });
    }

    function FetchTags(): Promise<Array<ITag>> {
        return new Promise<Array<ITag>>((resolve, reject) => {
            let url: string = config.endpointurl + "/Tags";

            let req: ReqData = {
                url: url,
                contenttype: "application/json",
                method: "GET"
            };

            ReqAuthenticated<Array<ITag>>(req).then(results => {
                resolve(results); //.map((v: any, i: number) => { return HydrateEntityTag(v); }));
            }).catch(error => {
                reject("API Error fetching tags: " + error.message);
            });
        });
    }

    function SaveTag(tag: ITag): Promise<ITag> {
        return new Promise<ITag>((resolve, reject) => {
            let url: string = config.endpointurl + "/Tag";
            let req: ReqData = {
                url: url,
                contenttype: "application/json",
                method: "POST",
                body: tag
            };

            ReqAuthenticated<ITag>(req).then(results => {
                resolve(results);
            }).catch(error => {
                reject("API Error on SaveTag");
            });
        });
    }

    function AddEntityTags(tags: Array<ITag>, entityid: string, entitytype: string): Promise<Array<IEntityTag>> {
        return new Promise<Array<IEntityTag>>((resolve, reject) => {
            let url: string = config.endpointurl + "/EntityTags/" + entitytype + "/" + entityid;
            let req: ReqData = {
                url: url,
                contenttype: "application/json",
                method: "POST",
                body: tags
            };

            ReqAuthenticated<Array<IEntityTag>>(req).then(results => {
                resolve(results);
            }).catch(error => {
                reject("API Error on AddEntityTag");
            });
        });
    }

    function AddEntityTag(tag: ITag, entityid: string, entitytype: string): Promise<Array<IEntityTag>> {
        return new Promise<Array<IEntityTag>>((resolve, reject) => {
            let taglist: Array<ITag> = []
            taglist.push(tag);
            let url: string = config.endpointurl + "/EntityTags/" + entitytype + "/" + entityid;
            let req: ReqData = {
                url: url,
                contenttype: "application/json",
                method: "POST",
                body: taglist
            };

            ReqAuthenticated<Array<IEntityTag>>(req).then(results => {
                resolve(results);
            }).catch(error => {
                reject("API Error on AddEntityTag");
            });
        });
    }

    function RemoveEntityTag(entitytag: IEntityTag, entityid: string, entitytype: string): Promise<Array<IEntityTag>> {
        return new Promise<Array<IEntityTag>>((resolve, reject) => {
            let url: string = config.endpointurl + "/EntityTags/" + entitytype + "/" + entityid + "/" + entitytag.entitytagid;
            let req: ReqData = {
                url: url,
                contenttype: "application/json",
                method: "DELETE"
            };

            ReqAuthenticated<Array<IEntityTag>>(req).then(results => {
                resolve(results);
            }).catch(error => {
                reject("API Error on AddEntityTag");
            });
        });
    }

    interface IUserSaveResult {
        success: boolean;
        item: IServiceUser | null;
    }

    function SaveUser(user: IServiceUser): Promise<IUserSaveResult> {
        return new Promise<IUserSaveResult>((resolve, reject) => {

            let url: string = config.endpointurl + "/Admin/User";
            let req: ReqData = {
                method: "POST",
                contenttype: "application/json",
                url: url,
                body: user
            };
            if (config.getaccesstoken != null) {
                return config.getaccesstoken().then((t) => {
                    let init: RequestInit = GenerateReqInit(req, t);
                    let url = req.url;
                    return fetch(url, init).then(result => {
                        if (result.ok) {                            
                            resolve({ success: true, item: null });
                            return;
                        }
                        else { resolve({ success: false, item: null }); }
                    }).catch(error => {
                        resolve({ success: false, item: null });
                    });
                });
            }
            else {
                throw new Error("Unauthenticated Request");
            }
        });
    }

    interface IOperationResult {
        success: boolean;
        message?: string | undefined;
    }

    function RemoveUser(user: IServiceUser): Promise<IOperationResult> { //, callback: (result: { success: boolean, message?: string }) => void): void {
        return new Promise<IOperationResult>((resolve, reject) => {
            let url: string = config.endpointurl + "/Admin/User";

            let req: ReqData = {
                contenttype: "application/json",
                method: "DELETE",
                body: user,
                url: url
            }
            //let req: RequestInit = {
            //    headers: {
            //        "Content-Type": "application/json",
            //        "Authorization": "Bearer " + this.authtoken
            //    },
            //    method: "DELETE",
            //    body: JSON.stringify(user)
            //};
            if (config.getaccesstoken != null) {
                return config.getaccesstoken().then((t) => {
                    let init: RequestInit = GenerateReqInit(req, t);
                    let url = req.url;

                    fetch(url, init).then(result => {
                        if (result.ok) { return "Success"; }
                        else { resolve({ success: false, message: "Failure" }); }
                    }).then(item => {
                        if (item != null) {
                            resolve({ success: true, message: "Success" });
                        }
                        else {
                            resolve({ success: false, message: "Failure" });
                        }
                    }).catch(error => {
                        resolve({ success: false, message: "Failure" });
                    });
                });
            }
            else {
                reject("Unauthenticated");
            }
        });
    }

    function ResetPassword(user: IServiceUser, newpassword: string): Promise<IOperationResult> {
        return new Promise<IOperationResult>((resolve, reject) => {
            let url: string = config.endpointurl + "/Admin/User/Reset";

            let req: ReqData = {
                contenttype: "application/json",
                method: "PUT",
                body: { data: user, update: newpassword },
                url: url
            }
            if (config.getaccesstoken != null) {
                return config.getaccesstoken().then((t) => {
                    let init: RequestInit = GenerateReqInit(req, t);

                    fetch(url, init).then(result => {
                        if (result.ok) { return result.text(); }
                        else { resolve({ success: false, message: "Failure" }); }
                    }).then(id => {
                        if (id != null) {
                            resolve({ success: true, message: id });
                        }
                        else {
                            resolve({ success: false, message: "Failure" });
                        }
                    }).catch(error => {
                        resolve({ success: false, message: error.message });
                    });
                });
            }
            else {
                reject("Unauthenticated");
            }
        });
    }

    interface IFileResult {
        success: boolean;
        filename: string;
        file: Blob | null;
    }

    function RequestDocument(request: IDocumentRequest): Promise<IFileResult> { //, callback: (result: { success: boolean, filename: string, file: Blob | null }) => void): void {
        return new Promise<IFileResult>((resolve, reject) => {
            let url: string = config.endpointurl + "/Documents";

            let req: ReqData = {
                contenttype: "application/json",
                method: "POST",
                body: request,
                url: url
            }

            if (config.getaccesstoken != null) {
                return config.getaccesstoken().then((t) => {
                    let init: RequestInit = GenerateReqInit(req, t);

                    fetch(url, init).then(result => {
                        if (result.ok) { return result.blob(); }
                        else { resolve({ success: false, filename: "N/A", file: null }); }
                    }).then(blob => {
                        if (blob != null) {
                            resolve({ success: true, filename: request.fromDate + "_" + request.toDate + "_" + request.mode + ".html", file: blob });
                        }
                        else {
                            resolve({ success: false, filename: "N/A", file: null });
                        }
                    }).catch(error => {
                        resolve({ success: false, filename: "N/A", file: null });
                    });
                });
            }
            else {
                reject("Unauthenticated");
            }
        });
    }

    function RequestVCard(request: IDocumentRequest): Promise<IFileResult> { //, callback: (result: { success: boolean, filename: string, file: Blob | null }) => void): void {
        return new Promise<IFileResult>((resolve, reject) => {
            let url: string = config.endpointurl + "/Documents";

            let req: ReqData = {
                contenttype: "application/json",
                method: "POST",
                body: request,
                url: url
            }

            if (config.getaccesstoken != null) {
                return config.getaccesstoken().then((t) => {
                    let init: RequestInit = GenerateReqInit(req, t);

                    fetch(url, init).then(result => {
                        if (result.ok) { return result.blob(); }
                        else { resolve({ success: false, filename: "N/A", file: null }); }
                    }).then(blob => {
                        let id: string = request.ids != null && request.ids.length > 0 ? request.ids[0] : "req";
                        if (blob != null) {
                            resolve({ success: true, filename: id + "_" + request.mode + ".vcf", file: blob });
                        }
                        else {
                            resolve({ success: false, filename: "N/A", file: null });
                        }
                    }).catch(error => {
                        resolve({ success: false, filename: "N/A", file: null });
                    });
                });
            }
            else {
                reject("Unauthenticated");
            }
        });
    }

    function FetchPeriodicals(): Promise<Array<ISubscription>> { //callback: (results: Array<ISubscription>) => void): void {
        let url: string = config.endpointurl + "/Periodical";
        let req: ReqData = {
            url: url,
            contenttype: "application/json",
            method: "GET"
        };

        return new Promise<Array<ISubscription>>((resolve, reject) => {
            ReqAuthenticated<Array<ISubscription>>(req)
                .then((data) => {
                    data.sort((a: ISubscription, b: ISubscription) => { return ('' + a.name).localeCompare(b.name); });
                    resolve(data);
                }).catch((r) => { reject("Error fetching periodicals list: " + r.message) });
        });

    }

    function FetchUserList(): Promise<Array<IServiceUser>> {
        return new Promise<Array<IServiceUser>>((resolve, reject) => {
            let url: string = config.endpointurl + "/Admin/User";

            let req: ReqData = {
                contenttype: "application/json",
                method: "GET",
                url: url
            };

            ReqAuthenticated<Array<IServiceUser>>(req).then(results => {
                resolve(results);
            }).catch(error => {
                alert("API Error fetching service users: " + error.message);
            });
        });
    }

    interface IApiResponse {
        success: boolean;
        message: string;
    }

    function ReceiveIssue(id: number, date: string): Promise<IApiResponse> { // callback: (response: ApiResponse) => void): void {
        let url: string = config.endpointurl + "/Periodical/" + id.toString() + "/Issue";
        let request: any = {
            datestring: date
        };
        let req: ReqData = {
            contenttype: "application/json",
            method: "POST",
            url: url,
            body: request
        };
        //let req: RequestInit = {
        //    headers: {
        //        "Content-Type": "application/json",
        //        "Authorization": "Bearer " + this.authtoken
        //    },
        //    method: "POST",
        //    body: JSON.stringify(request)
        //};

        return SendAuthenticated(req);
    }

    function SaveSubscription(data: ISubscription): Promise<IApiResponse> {//, callback: (response: IApiResponse) => void): void {
        let url: string = config.endpointurl + "/Periodical/";
        let request: ISubscription = { ...data, received: [] };
        let req: ReqData = {
            contenttype: "application/json",
            method: "POST",
            url: url,
            body: request
        };

        return SendAuthenticated(req);
    }

    function DeleteSubscription(data: ISubscription): Promise<IApiResponse> {
        let url: string = config.endpointurl + "/Periodical/" + data.id;

        let req: ReqData = {
            contenttype: "application/json",
            method: "DELETE",
            url: url
        };

        return SendAuthenticated(req);

    }

    function DeleteIssue(data: ISubscription, issuedate: string): Promise<IApiResponse> {//, callback: (response: IApiResponse) => void): void {
        let url: string = config.endpointurl + "/Periodical/" + data.id + "/Issue/" + issuedate;


        let req: ReqData = {
            contenttype: "application/json",
            method: "DELETE",
            url: url
        };

        return SendAuthenticated(req);
    }

    function FetchMailCategories(): Promise<Array<string>> {
        return new Promise<Array<string>>((resolve, reject) => {
            let url: string = config.endpointurl + "/MailCategories/";
            let req: ReqData = {
                url: url,
                method: "GET",
                contenttype: "application/json"
            };

            ReqAuthenticated<Array<string>>(req).then(results => {
                resolve(results);
            }).catch(error => {
                alert("API Error fetching mail categories: " + error.message);
            });
        });
    }

    function FetchContactNames(): Promise<Array<string>> {
        return new Promise<Array<string>>((resolve, reject) => {
            let url: string = config.endpointurl + "/Senders";
            let req: ReqData = {
                url: url,
                method: "GET",
                contenttype: "application/json"
            };

            ReqAuthenticated<Array<string>>(req).then(results => {
                resolve(results);
            }).catch(error => {
                alert("API Error fetching contact names: " + error.message);
            });
        });
    }

    function FetchFormData(): Promise<IFormData> { //callback: (data: IFormData) => void): void {
        return new Promise<IFormData>((resolve, reject) => {
            FetchMailCategories().then(cats => {
                FetchContactNames().then(names => {
                    resolve({ categories: cats, contacts: names });
                });
            });
        });
    }

    function RetireCorrespondentName(name: string): Promise<Array<string>> {
        let url: string = config.endpointurl + "/Senders";
        let bodydata = { value: name };

        let req: ReqData = {
            contenttype: "application/json",
            method: "DELETE",
            url: url,
            body: bodydata
        };

        return ReqAuthenticated(req);
    }

    function GetContactImageUrl(id: string): string {
        let url: string = config.endpointurl + "/Contact/" + id + "/Image";

        return url;
    }


    function SaveContact(contact: IContactDetails): Promise<string> { //, callback: (responsestring: string) => void): void {
        return new Promise<string>((resolve, reject) => {
            let url: string = config.endpointurl + '/Contact/';
            let req: ReqData = {
                method: "POST",
                url: url,
                contenttype: "application/json",
                body: contact
            };


            ReqAuthenticated<IContactDetails>(req)
                .then((response) => {
                    if (response == null) {
                        resolve("Failure");
                    }
                    resolve(response.id);
                })
                .catch((error) => {
                    resolve("Error");
                });
        });
    }


    function DeleteContact(contactid: string): Promise<IApiResponse> { // | null, callback: () => void): void {
        return new Promise<IApiResponse>((resolve, reject) => {
            if (contactid != null) {
                let url: string = config.endpointurl + '/Contact/' + contactid;

                let req: ReqData = {
                    url: url,
                    contenttype: "application/json",
                    method: "DELETE"
                }

                SendAuthenticated(req).then((response) => {
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err.message);
                    });
            }
        });
    }
    

    function DeleteMailItem(mailitem: number): Promise<IApiResponse> { // | null, callback: () => void): void {
        return new Promise<IApiResponse>((resolve, reject) => {
            if (mailitem != null) {
                let url: string = config.endpointurl + '/MailLog/' + mailitem;

                let req: ReqData = {
                    url: url,
                    contenttype: "application/json",
                    method: "DELETE"
                }

                SendAuthenticated(req).then((response) => {
                    resolve(response);
                })
                    .catch((err) => {
                        reject(err.message);
                    });
            }
        });
    }

    return {
        DeleteIssue,
        DeleteSubscription,
        DeleteContact,
        DeleteMailItem,
        FetchContact,
        FetchContactNames,
        FetchDirectoryEntries,
        FetchEntityTags,
        FetchPeriodicals,
        FetchTags,
        FetchUserList,
        FetchFormData,
        FetchMailLog,
        FetchMailLogEntry,
        GetStates,
        GetInternational,
        GetPersonal,
        GetOtherOptions,
        GetContactImageUrl,
        SaveContact,
        SaveMailLogEntry,
        SaveSubscription,
        SaveTag,
        SaveUser,
        AddEntityTags,
        AddEntityTag,
        ReceiveIssue,
        RemoveEntityTag,
        RemoveUser,
        RequestDocument,
        RequestVCard,
        ResetPassword,
        RetireCorrespondentName,
        GetUserInfo
    };
}